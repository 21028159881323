import _ from 'lodash';
import { createSelector } from 'reselect';
import { getVideoIdFromAppSettings, isSingleVideo, } from '../../shared/selectors/app-settings';
import { getVideoIdFromUrl } from './query-params';
import { getVideosGroupedByIds } from '../../shared/selectors/videos';
export var getVideoIdToSelect = createSelector([isSingleVideo, getVideoIdFromAppSettings, getVideoIdFromUrl], function (isSingleVideo, singleVideoId, videoIdFromUrl) {
    var videoIdToSelect = isSingleVideo ? singleVideoId : videoIdFromUrl;
    return videoIdToSelect;
});
export var videoToSelectExists = createSelector([getVideoIdToSelect, getVideosGroupedByIds], function (videoIdToSelect, videos) {
    return !_.isEmpty(videos[videoIdToSelect]);
});
